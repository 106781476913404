<template>
  <div>
    <el-dialog
      :title="title + '设备模板'"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="酒店" prop="hotelId">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.hotelId"
                  placeholder="请选择酒店"
                  :disabled="hotel"
                >
                  <el-option
                    v-for="item in hotelList"
                    :key="item.id"
                    :label="item.hotelName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="房型" prop="hotelId">
                <el-input
                  :disabled="hotel"
                  v-model="typeName"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="设备类型" prop="deviceType">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.deviceType"
                  placeholder="请选择设备类型"
                  :disabled="setAble"
                >
                  <el-option
                    v-for="item in deviceList"
                    :key="item.code"
                    :label="item.typeName"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="设备别名" prop="deviceNameId">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.deviceNameId"
                  allow-create
                  filterable
                  clearable
                  default-first-option
                  @change="WOStatusChange"
                  placeholder="请选择设备别名"
                >
                  <el-option
                    v-for="item in DeviceList"
                    :key="item.id"
                    :label="item.deviceOtherName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <!-- <div class="banben"> -->

          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="房间区域" prop="roomArea">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.roomArea"
                  placeholder="请选择房间区域"
                >
                  <el-option
                    v-for="item in roomAreaList"
                    :key="item.id"
                    :label="item.roomArea"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="位号" prop="placeNo" style="width: 100%">
                <el-input
                  placeholder="请输入位号"
                  v-model="formInline.placeNo"
                  style="width: 100%"
                  type="number"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="设备号" prop="deviceNo" style="width: 100%">
                <el-input
                  placeholder="请输入设备号"
                  v-model="formInline.deviceNo"
                  style="width: 100%"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </div>

            <div class="bandanItem" style="width: 45%" v-if="title == '编辑'">
              <el-form-item label="排序" prop="dispOrder" style="width: 100%">
                <el-input
                  placeholder="请输入排序"
                  v-model="formInline.dispOrder"
                  style="width: 100%"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="小程序可见" prop="display">
                <el-radio-group v-model="formInline.display">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="同步小度" prop="display">
                <el-radio-group v-model="formInline.synXiaodu">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
            <el-button
              v-if="title == '创建'"
              size="small"
              type="primary"
              @click="createStorage2('formInline')"
              >继续添加</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      index: null,
      typeName: null,
      hotel: true,
      setAble: false,
      //表单
      formInline: {
        display: 1,
        synXiaodu: 1,
        // deviceNameId: 1,
      },
      hotelList: [],
      roomStatusList: [],
      hotelTypeList: [],
      deviceList: [],
      roomAreaList: [],
      DeviceList: [],
      //表单验证
      rules: {
        hotelId: [
          {
            required: true,
            message: "请选择酒店",
            trigger: "blur",
          },
        ],
        deviceNameId: [
          {
            required: true,
            message: "请选择设备别名",
            trigger: "blur",
          },
        ],
        deviceType: [
          {
            required: true,
            message: "请选择设备类型",
            trigger: "blur",
          },
        ],
        roomArea: [
          {
            required: true,
            message: "请选择房间区域",
            trigger: "blur",
          },
        ],
        placeNo: [
          {
            required: true,
            message: "请输入位号",
            trigger: "blur",
          },
        ],
        deviceNo: [
          {
            required: true,
            message: "请输入设备号",
            trigger: "blur",
          },
        ],
        controlNum: [
          {
            required: true,
            message: "请输入空调温度",
            trigger: "blur",
          },
        ],
        // dispOrder: [
        //   {
        //     required: true,
        //     message: "请输入排序",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, id, typeName, val) {
      this.formInline = { display: 1, synXiaodu: 1 };
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      this.formInline.hotelId = hotelInfor.hotelId;
      this.typeName = typeName;
      console.log(222, this.typeName);
      console.log(5555, id);
      this.formInline.roomType = id;
      this.getHotelList();
      // this.getRoomStatusList();
      this.getHotelTypeList();
      this.getDeviceList();
      this.getRoomAreaList();
      this.getdeviceList();
      this.formInline.deviceName = null;
      this.title = item;
      this.showing = true;
      this.bedisabled = false;
      this.setAble = false;

      if (item == "编辑") {
        this.setAble = true;
        this.bedisabled = true;
        this.formInline = JSON.parse(JSON.stringify(val));
        console.log(111, this.formInline);
        this.formInline.display = Number(this.formInline.display);
        this.formInline.synXiaodu = Number(this.formInline.synXiaodu);
        console.log(this.formInline);
      }
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomStatusList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomStatusList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getHotelTypeList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelType", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelTypeList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getDeviceList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysDeviceType", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.deviceList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getdeviceList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelDeviceDefinition", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.DeviceList = res.data;
          console.log(345, this.deviceList);
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomAreaList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        num: 10000,
        offset: 1,
      };
      postRequest("selectAllHotelRoomArea", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomAreaList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    createStorage(formData) {
      // 表单验证-----------

      // 新增
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.title == "创建") {
            this.roomAreaList.forEach((item) => {
              if (item.id == this.formInline.roomArea) {
                this.formInline.roomAreaName = item.roomArea;
              }
            });
            let parms = {};
            console.log(555, this.formInline.deviceName);
            // return;
            if (this.formInline.deviceName) {
              parms = {
                deviceName: this.formInline.deviceName,
                deviceNameId: null,
                deviceNo: this.formInline.deviceNo,
                deviceType: this.formInline.deviceType,
                dispOrder: this.formInline.dispOrder,
                display: this.formInline.display,
                hotelId: this.formInline.hotelId,
                placeNo: this.formInline.placeNo,
                roomArea: this.formInline.roomArea,
                roomAreaName: this.formInline.roomAreaName,
                roomType: this.formInline.roomType,
                synXiaodu: this.formInline.synXiaodu,
              };
            } else {
              parms = {
                deviceName: null,
                deviceNameId: this.formInline.deviceNameId,
                deviceNo: this.formInline.deviceNo,
                deviceType: this.formInline.deviceType,
                dispOrder: this.formInline.dispOrder,
                display: this.formInline.display,
                hotelId: this.formInline.hotelId,
                placeNo: this.formInline.placeNo,
                roomArea: this.formInline.roomArea,
                roomAreaName: this.formInline.roomAreaName,
                roomType: this.formInline.roomType,
                synXiaodu: this.formInline.synXiaodu,
              };
            }
            postRequest("/insertHotelDeviceTemplate", parms).then((res) => {
              if (res.status == 200) {
                this.$message({
                  duration: 5000,
                  message: "创建成功!",
                  type: "success",
                });
                this.hide();
                this.$refs.formInline.resetFields(); //清空表单
                this.$parent.getFounderList();
              } else {
                this.$message({
                  duration: 5000,
                  message: res.message,
                  type: "warning",
                });
              }
            });
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    createStorage2(formData) {
      // 表单验证-----------

      // 新增
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.title == "创建") {
            this.roomAreaList.forEach((item) => {
              if (item.id == this.formInline.roomArea) {
                this.formInline.roomAreaName = item.roomArea;
              }
            });
            let parms = {};
            console.log(555, this.formInline.deviceName);
            // return;
            if (this.formInline.deviceName) {
              parms = {
                deviceName: this.formInline.deviceName,
                deviceNameId: null,
                deviceNo: this.formInline.deviceNo,
                deviceType: this.formInline.deviceType,
                dispOrder: this.formInline.dispOrder,
                display: this.formInline.display,
                hotelId: this.formInline.hotelId,
                placeNo: this.formInline.placeNo,
                roomArea: this.formInline.roomArea,
                roomAreaName: this.formInline.roomAreaName,
                roomType: this.formInline.roomType,
                synXiaodu: this.formInline.synXiaodu,
              };
            } else {
              parms = {
                deviceName: null,
                deviceNameId: this.formInline.deviceNameId,
                deviceNo: this.formInline.deviceNo,
                deviceType: this.formInline.deviceType,
                dispOrder: this.formInline.dispOrder,
                display: this.formInline.display,
                hotelId: this.formInline.hotelId,
                placeNo: this.formInline.placeNo,
                roomArea: this.formInline.roomArea,
                roomAreaName: this.formInline.roomAreaName,
                roomType: this.formInline.roomType,
                synXiaodu: this.formInline.synXiaodu,
              };
            }
            postRequest("/insertHotelDeviceTemplate", parms).then((res) => {
              if (res.status == 200) {
                this.$message({
                  duration: 5000,
                  message: "创建成功!",
                  type: "success",
                });
                this.$refs.formInline.resetFields(); //清空表单
                this.$parent.getFounderList();
              } else {
                this.$message({
                  duration: 5000,
                  message: res.message,
                  type: "warning",
                });
              }
            });
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      let parms = {};
      if (this.formInline.deviceName) {
        parms = {
          deviceName: this.formInline.deviceName,
          deviceNameId: null,
          deviceNo: this.formInline.deviceNo,
          deviceType: this.formInline.deviceType,
          dispOrder: this.formInline.dispOrder,
          display: this.formInline.display,
          hotelId: this.formInline.hotelId,
          placeNo: this.formInline.placeNo,
          roomArea: this.formInline.roomArea,
          roomAreaName: this.formInline.roomAreaName,
          roomType: this.formInline.roomType,
          synXiaodu: this.formInline.synXiaodu,
          id: this.formInline.id,
        };
      } else {
        parms = {
          deviceName: null,
          deviceNameId: this.formInline.deviceNameId,
          deviceNo: this.formInline.deviceNo,
          deviceType: this.formInline.deviceType,
          dispOrder: this.formInline.dispOrder,
          display: this.formInline.display,
          hotelId: this.formInline.hotelId,
          placeNo: this.formInline.placeNo,
          roomArea: this.formInline.roomArea,
          roomAreaName: this.formInline.roomAreaName,
          roomType: this.formInline.roomType,
          synXiaodu: this.formInline.synXiaodu,
          id: this.formInline.id,
        };
      }
      postRequest("/updateHotelDeviceTemplate", parms).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    //附件
    //上传附件----------------------------------------------
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      let isFlag = true;
      if (!isLt5M) {
        this.$message.error("上传头像文件大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //--------------------------------------------------------------------
    // 成功之后的返回
    handleAvatarSuccess(res, file) {
      this.fileJson.push(res.data);
      this.fileListUpload.push(res.data);
    },

    // 删除之后的返回值
    handleRemove(file, fileList) {
      this.fileJson.forEach((fileId, index) => {
        if (fileId.id == file.id) {
          this.fileJson.splice(index, 1);
          this.fileListUpload.splice(index, 1);
        }
      });
    },
    deviceFilter(val) {},
    //预览
    handlePreview(file) {
      let fileName = file.fileName;
      //获取最后一个.的位置
      let index = fileName.lastIndexOf(".");
      //获取后缀
      let ext = fileName.substr(index + 1);
      if (this.isAssetTypeAnImage(ext)) {
        //图片预览
        window.open(
          window.string.BUSINESS_PUBLIC_API_URL +
            "/sys/attachments/getImageByStream/?id=" +
            file.id
        );
      } else {
        //永中预览
        previewUpload(file.id).then(({ data, result, message }) => {
          if (result == 1) {
            var previewUrl = data.filePath;
            window.open(window.string.PUBLIC_VIEW_FILE_API + previewUrl);
          } else {
            this.$message({
              duration: 5000,
              message: message,
              type: "warning",
            });
          }
        });
      }
    },
    isAssetTypeAnImage(ext) {
      return (
        ["png", "jpg", "jpeg", "bmp", "gif"].indexOf(ext.toLowerCase()) !== -1
      );
    },
    handleDowload(file) {
      window.location.href =
        window.string.BUSINESS_PUBLIC_API_URL +
        "/sys/attachments/donwload/?id=" +
        file.id;
    },
    WOStatusChange(value) {
      // let res = typeof value;
      // this.DeviceList.forEach((item) => {
      console.log(113, typeof value);
      if (typeof value === "string") {
        this.formInline.deviceName = value;
      } else {
        this.formInline.deviceNameId = value;
        this.formInline.deviceName = null;
      }
      // });
      console.log(112, this.formInline.deviceName);
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
</style>