<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <el-form-item label="目标设备模板" prop="deviceSec">
            <el-select
              class="text"
              style="width: 100%"
              v-model="formInline.deviceSec"
              ref="deviceSec"
              placeholder="请选择目标设备模板"
            >
              <el-option
                v-for="item in deviceSecList"
                :key="item.id"
                :label="item.deviceName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="Password('formInline')"
              >确定复制</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRequest, postRequest } from "@/api/api.js";
import { regMatch } from "../../utils/reg";
export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      index: null,
      infor: {},
      //表单
      formInline: {
        deviceSec: null,
      },
      deviceSecList: [],
      //表单验证
      rules: {
        deviceSec: [
          {
            required: true,
            message: "请选择目标设备模板",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, val) {
      this.title = item;
      this.showing = true;
      this.formInline = {};
      console.log(111, val);
      this.infor = val;
      this.getDeviceSecList();
    },
    getDeviceSecList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        num: 100000,
        offset: 1,
        roomType: this.infor.roomType,
      };
      postRequest("selectAllHotelDeviceTemplate", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          let list1 = res.data.list;
          let list2 = [];
          list1.forEach((element) => {
            if (
              element.deviceTypeName != this.infor.deviceTypeName &&
              (element.ioType == 0 || element.ioType == 2)
            ) {
              list2.push(element);
            }
          });
          this.deviceSecList = list2;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    Password(formData) {
      // 表单验证-----------
      if (!regMatch(this.formInline.deviceSec, "目标设备模板")) {
        this.$refs.deviceSec.focus();
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parmst = {
        deviceOne: this.infor.id,
        deviceSec: this.formInline.deviceSec,
      };
      postRequest("/CopyDeviceControl", parmst).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "复制成功!",
            type: "success",
          });
          this.hide();
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.banbentwo {
  width: 100%;
}
.banbentwoItem {
  width: 100%;
  margin-bottom: 16px;
}
.banbentwoItem > textarea {
  width: 100%;
  height: 170px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
  padding-top: 10px;
}
textarea::-webkit-input-placeholder {
  color: #000000;
  opacity: 0.25;
}
.banbentwoItem > div:first-child {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
</style>