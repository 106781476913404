<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">设备模板管理 ({{ infor.typeName }})</div>
        <div>
          <el-button
            v-if="chuangjian"
            type="primary"
            @click="addIdType(0, null)"
            class="addIdType"
            >+ 设备模板</el-button
          >
          <el-button
            v-if="oneClick"
            type="primary"
            @click="oneClickConfig(0, null)"
            class="addIdType"
            >一键下发配置</el-button
          >
          <el-button
            v-if="piliang"
            type="primary"
            @click="changeUpdata()"
            class="addIdType"
            >批量修改上传小度</el-button
          >
          <el-button type="primary" @click="addIdType1" class="addIdType1"
            >返回上一级</el-button
          >
        </div>
      </div>
    </div>
    <!-- <el-card style="margin-bottom: 20px">
      <el-form :inline="true" :model="condition">
        <el-form-item label="酒店" prop="hotelId">
          <el-select
            class="text"
            style="width: 100%"
            v-model="condition.hotelId"
            placeholder="请选择酒店"
          >
            <el-option
              v-for="item in hotelList"
              :key="item.id"
              :label="item.hotelName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备别名" prop="deviceNameId">
          <el-select
            class="text"
            style="width: 100%"
            v-model="condition.deviceNameId"
            placeholder="请选择设备别名"
          >
            <el-option
              v-for="item in DeviceList"
              :key="item.id"
              :label="item.deviceOtherName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型" prop="deviceType">
          <el-select
            class="text"
            style="width: 100%"
            v-model="condition.deviceType"
            placeholder="请选择设备类型"
          >
            <el-option
              v-for="item in deviceList"
              :key="item.typeCode"
              :label="item.typeName"
              :value="item.typeCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间区域" prop="roomArea">
          <el-select
            class="text"
            style="width: 100%"
            v-model="condition.roomArea"
            placeholder="请选择房间区域"
          >
            <el-option
              v-for="item in roomAreaList"
              :key="item.id"
              :label="item.roomArea"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="addIdType2" type="primary" @click="getFounderList()"
            >查询</el-button
          >
        </el-form-item>
        <el-button
          v-if="chuangjian"
          type="primary"
          @click="addIdType(0, null)"
          class="addIdType"
          >+ 设备模板</el-button
        >
      </el-form>
    </el-card> -->

    <div class="hl-content">
      <!-- <div class="mokuai">设备模板列表</div> -->
      <el-table
        ref="multipleTable"
        class="hl-table"
        :data="tableData"
        height="90%"
        @selection-change="handleChoose"
        @row-click="rowClicks"
        :header-cell-style="{ background: '#F4F5F9', color: '#000000' }"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <!-- <el-table-column label="房间状态">
          <template slot-scope="scope">
            <span>{{ scope.row.roomStatus }}</span>
          </template>
        </el-table-column> -->

        <el-table-column label="设备别名">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备类型">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceTypeName }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="设备类型编号">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceTypeCode }}</span>
          </template>
        </el-table-column> -->

        <el-table-column label="位号" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.placeNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备号">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="房间区域">
          <template slot-scope="scope">
            <span>{{ scope.row.roomAreaName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="同步小度">
          <template slot-scope="scope">
            <!-- <div class="zt">
              <div v-if="scope.row.synXiaodu == 1">
                <span>是</span>
              </div>
              <div v-else>
                <span>否</span>
              </div>
            </div> -->
            <el-switch
              v-model="scope.row.synXiaodu"
              active-color="#387dff"
              inactive-color="#ff4949"
              active-value="1"
              inactive-value="0"
              @change="changeArStatus(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="小程序可见">
          <template slot-scope="scope">
            <!-- <div class="zt">
              <div v-if="scope.row.display == 1">
                <span>是</span>
              </div>
              <div v-else>
                <span>否</span>
              </div>
            </div> -->
            <el-switch
              v-model="scope.row.display"
              active-color="#387dff"
              inactive-color="#ff4949"
              active-value="1"
              inactive-value="0"
              @change="changeArStatus(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            <span>{{ scope.row.dispOrder }}</span>
          </template>
        </el-table-column>
        <el-table-column label="控制数量">
          <template slot-scope="scope">
            <span>{{ scope.row.controlNum }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <div class="cz">
              <div class="qidong">
                <el-button
                  v-if="xiugai"
                  type="text"
                  @click="edit(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  style="color: red"
                  type="text"
                  v-if="shanchu"
                  @click="del(scope.row)"
                  >删除</el-button
                >
                <!-- <div> -->
                <el-button
                  class="cz-button"
                  size="mini"
                  v-if="
                    control && (scope.row.ioType == 0 || scope.row.ioType == 2)
                  "
                  @click="contrl(scope.row)"
                  >控制配置</el-button
                >
                <el-button
                  class="cz-button1"
                  size="mini"
                  v-if="
                    (cope && scope.row.ioType == 0) || scope.row.ioType == 2
                  "
                  @click="copy(scope.row)"
                  >复制至</el-button
                >
                <!-- </div> -->
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @size-change="onPaginationSize"
          @current-change="onPaginationIndex"
          :current-page="condition.offset"
          :page-size="condition.num"
          layout=" prev, pager, next, jumper,total"
          :total="totolNums"
        ></el-pagination>
      </div>
    </div>
    <!-- 删除 -->
    <el-dialog title="提示" :visible.sync="deleteVisible" width="30%">
      <el-input
        placeholder="请输入删除指示"
        v-model="todelete"
        style="width: 100%"
        maxlength="10"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
    <addModify ref="addModify" />
    <disableModify ref="disableModify" />
  </div>
</template>
<script>
// import { querySelectAllUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";
import addModify from "@/views/setTemplate/addModify.vue";
import disableModify from "@/views/setTemplate/disableModify.vue";
export default {
  data() {
    return {
      tableData: [],
      list: [],
      hotelList: [],
      roomStatusList: [],
      deviceList: [],
      hotelTypeList: [],
      roomAreaList: [],
      DeviceList: [],
      input: "",
      status: 0,
      versionCode: "",
      versionName: "",
      statusRemark: "",
      totolNums: null,
      condition: {
        hotelId: null,
        num: 10,
        offset: null,
      },
      chuangjian: false,
      xiugai: false,
      shanchu: false,
      cope: false,
      control: false,
      infor: {},
      deleteVisible: false,
      oneClick: false,
      piliang: false,
      row: {},
      todelete: "",
      batchList: [],
    };
  },
  components: {
    addModify,
    disableModify,
  },
  created() {
    console.log(this.$store.state.offset);
    // ret
    if (this.$store.state.offset != null) {
      this.condition.offset = this.$store.state.offset;
    } else {
      this.condition.offset = 1;
    }
    this.infor = this.$route.query;
    this.condition.roomType = this.infor.id;
    if (!this.power.checkedpower("0304008")) {
      // 在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // 创建
    this.chuangjian = this.power.checkedpower("0304005");
    // 修改
    this.xiugai = this.power.checkedpower("0304006");
    this.oneClick = this.power.checkedpower("0304017");
    // 删除，禁用
    this.shanchu = this.power.checkedpower("0304007");
    this.cope = this.power.checkedpower("0304013");
    this.control = this.power.checkedpower("0304012");
    this.piliang = this.power.checkedpower("0304018");

    this.getFounderList();
    this.getHotelList();
    this.getHotelTypeList();
    this.getDeviceList();
    this.getRoomAreaList();
    // this.getdeviceList();
  },
  methods: {
    changeArStatus(row) {
      postRequest("/updateHotelDeviceTemplate", row).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getHotelTypeList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelType", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelTypeList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getDeviceList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysDeviceType", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.deviceList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomAreaList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        num: 10000,
        offset: 1,
      };
      postRequest("selectAllHotelRoomArea", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomAreaList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getdeviceList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelDeviceDefinition", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.DeviceList = res.data;
          console.log(345, this.deviceList);
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 获取创始人列表
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelDeviceTemplate", this.condition).then(
        (res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            this.tableData = res.data.list;
            this.totolNums = res.data.total;
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
            });
          }
        }
      );
    },
    // 删除
    del(row) {
      this.deleteVisible = true;
      this.todelete = "";
      this.row = row;
    },
    deleteSure() {
      if (this.todelete != "yes") {
        this.$message({
          duration: 5000,
          message: "请输入指示！",
          center: true,
        });
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        id: this.row.id,
      };
      postRequest("deleteHotelDeviceTemplate", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "删除成功!",
            type: "success",
          });
          this.deleteVisible = false;
          this.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    contrl(row) {
      // let parms = {
      //   roomType: this.infor.id,
      //   controlDid: row.id,
      //   deviceTypeName: row.deviceTypeName,
      // };
      row.roomType = this.infor.id;
      this.$router.push({
        name: "contrl",
        query: row,
      });
    },
    // 禁用
    copy(row) {
      let state = "复制控制关系";
      this.$refs.disableModify.show(state, row);
    },
    search() {},
    // 新增
    addIdType() {
      let state = "创建";
      let id = this.infor.id;
      let typeName = this.infor.typeName;
      this.$refs.addModify.show(state, id, typeName);
    },
    // 修改
    edit(index, row) {
      let state = "编辑";
      let id = this.infor.id;
      let typeName = this.infor.typeName;

      this.$refs.addModify.show(state, id, typeName, row);
    },
    // 每页的数据数
    onPaginationSize(Sizeval) {
      this.condition.num = Sizeval;
      this.getFounderList();
    },
    // 页数
    onPaginationIndex(Indexpage) {
      this.condition.offset = Indexpage;
      this.$store.state.offset = Indexpage;
      console.log(4444, this.$store.state.offset);
      this.getFounderList();
    },
    addIdType1() {
      this.$router.go(-1);
    },
    rowClicks(row, col, event) {
      this.$refs.multipleTable.toggleRowSelection(row, row.flag);
    },
    handleChoose(val) {
      this.batchList = val;
      // this.getJuris(val);
    },
    // 批量修改上传小度
    changeUpdata() {
      if (this.batchList.length == 0) {
        this.$message({
          duration: 5000,
          message: "请选择批量修改上传数据！",
          type: "warning",
        });
        return;
      } else {
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        let ids = [];
        this.batchList.forEach((item) => {
          ids.push(item.id);
        });
        postRequest("editRoomDeviceXiaoDu", ids).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            this.$message({
              duration: 5000,
              message: "批量修改成功！",
              type: "success",
            });
            this.$refs.multipleTable.clearSelection();
            this.getFounderList();
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
            });
          }
        });
      }
    },
    // 一键下发配置
    oneClickConfig() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      getRequest("sendAllDeviceControl?roomType=" + this.infor.id).then(
        (res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            // this.getFounderList();
            this.$message({
              duration: 5000,
              message: "一键下发配置成功！",
              type: "success",
            });
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
            });
          }
        }
      );
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.el-card {
  position: relative;
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  /* position: absolute;
  right: 20px; */
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
  overflow: scroll;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
  margin-left: 10px;
}
.cz-button1 {
  width: 74px;
  height: 28px;
  background: #387dff;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
  margin-left: 10px;
}
.qidong {
  /* width: 90px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 10%;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}

.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.addIdType2 {
  width: 100px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.addIdType1 {
  width: 136px;
  height: 40px;
  color: #387dff;
  background: #d7e4fc;
  border-radius: 4px;
}
.dialog-footer > .el-button {
  width: 60px;
  height: 30px;
}
>>> .hl-table {
  overflow: scroll;
  height: 90%;
}
.el-table {
  height: 90%;
  overflow: hidden;
}
.block {
  position: absolute;
  bottom: 10px;
  right: 32px;
}
</style>